/* VoucherForm.css */

.voucher-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.voucher-details,
.rules-section {
  margin-bottom: 20px;
}

.voucher-details div,
.rules-section div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.voucher-details h2,
.rules-section h2 {
  margin-bottom: 10px;
}

.voucher-details label,
.rules-section label {
  width: 120px;
  font-weight: bold;
}

.voucher-details input[type="text"],
.voucher-details input[type="date"],
.rules-section input[type="text"] {
  flex: 1;
  padding: 8px;
}

.voucher-details input[type="number"] {
  flex: 1;
  padding: 8px;
  /* -moz-appearance: textfield; */
}

.rules-section .rule {
  margin-bottom: 10px;
}

.rules-section button {
  margin-top: 10px;
}

.danger-button {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.success-button {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.query-builder {
  display: flex;
  flex-direction: column;
}

.query-rule-group {
  margin-bottom: 10px;
}

.query-add-group {
  margin-top: 10px;
}

.query-group-actions {
  display: flex;
  flex-direction: column;
}

.query-group-add-action {
  margin-top: 10px;
}

@media (max-width: 600px) {
  .voucher-details div,
  .rules-section div {
    flex-direction: column;
    align-items: flex-start;
  }

  .voucher-details label,
  .rules-section label {
    width: auto;
  }

  .voucher-details input[type="text"],
  .voucher-details input[type="date"],
  .voucher-details input[type="number"],
  .rules-section input[type="text"] {
    width: 100%;
  }
}

/* Styles for the table */
table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

th {
  text-align: inherit;
}

thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

td,
th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table {
  background-color: rgba(223, 242, 220, 0.953);
  border: 3px solid #011817;
  font-weight: 700;
  color: rgb(18, 18, 41);
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 10px 10px mediumpurple;
}

.table th,
.table td {
  white-space: nowrap;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.953);
}

.table-bordered {
  border: 2px solid #011817;
}

.table-bordered th,
.table-bordered td {
  border: 2px solid #011817;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(223, 242, 220, 0.953);
}

.table-striped tbody tr:hover {
  background-color: rgba(223, 242, 220, 0.678);
}

.voucher-details-modal {
  max-width: 600px;
  width: 100%;
  border-radius: 10px;
}

/* Voucher title */
.voucher-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Content container */
.voucher-details-content {
  padding: 20px;
}

/* Individual voucher property */
.voucher-property {
  margin-bottom: 10px;
}

/* Property label */
.property-label {
  font-weight: bold;
  color: #333;
}

/* Voucher details background */
.voucher-details {
  margin: 15px 0;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 5px;
}
.rules-section {
  background-color: azure;
}
.updatedBy-section {
  background-color: beige;
}

/* Voucher sections styling */
.voucher-section {
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
}

/* Voucher section title */
.voucher-section-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

/* Rules list */
.rules-list {
  list-style: none;
  padding-left: 0;
}

/* Individual rule item */
.rule-item {
  margin-bottom: 5px;
  color: #555;
}

/* Last Updated By list */
.updated-users-list {
  list-style: none;
  padding-left: 0;
}

/* Individual updated user item */
.updated-user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

/* Updated user name */
.updated-user-name {
  font-weight: bold;
  color: #333;
}

/* Updated user timestamp */
.updated-user-timestamp {
  color: #888;
}
